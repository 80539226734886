
import { mapState } from 'vuex';
import getJSONLD from '@/utils/structured-data';
import getUrl from '@/utils/helpers/get-url';

export default {
  props: {
    withGrayBackground: {
      type: Boolean,
      default: false,
    },
  },
  head() {
    const { baseUrl } = getUrl(this.$nuxt.context.req);
    return {
      script: this.layout.breadcrumbs?.length
        ? [
            getJSONLD('breadcrumb', {
              links: this.layout.breadcrumbs,
              baseUrl,
              currentUrl: this.$route.path,
            }),
          ]
        : [],
    };
  },
  computed: mapState(['layout']),
};
