
import { getSliceComponentProps } from '@prismicio/vue/components';

export default {
  name: 'Breadcrumbs',
  components: {
    breadcrumbs: () => import('@/components/breadcrumbs'),
  },
  props: getSliceComponentProps(['slice']),
};
